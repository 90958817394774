var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { defineStore } from 'pinia';
import { ref, watch, watchEffect } from 'vue';
import { Expose, instanceToPlain, plainToInstance } from '../../shared/app/class-transformer-custom';
const LOCAL_SETTINGS_KEY = 'hex-local-settings';
export class LocalSettings {
    constructor() {
        /**
         * Dark or light theme.
         * "auto" for system theme.
         */
        this.selectedTheme = 'auto';
        /**
         * Selected board orientation.
         *
         * 'auto' by default, which means board uses player's preferred orientation,
         * depending of screen ratio, and rotate with screen,
         *
         * or player can force to use his preferred landscape or portrait orientation
         * no matter current screen orientation.
         */
        this.selectedBoardOrientation = 'auto';
    }
}
__decorate([
    Expose(),
    __metadata("design:type", String)
], LocalSettings.prototype, "selectedTheme", void 0);
__decorate([
    Expose(),
    __metadata("design:type", String)
], LocalSettings.prototype, "selectedBoardOrientation", void 0);
__decorate([
    Expose(),
    __metadata("design:type", Boolean)
], LocalSettings.prototype, "openSidebar", void 0);
const loadLocalSettings = () => {
    const serialized = localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem(LOCAL_SETTINGS_KEY);
    if (!serialized) {
        return new LocalSettings();
    }
    return plainToInstance(LocalSettings, JSON.parse(serialized));
};
const saveLocalSettings = (localSettings) => {
    const serialized = JSON.stringify(instanceToPlain(localSettings));
    localStorage === null || localStorage === void 0 ? void 0 : localStorage.setItem(LOCAL_SETTINGS_KEY, serialized);
};
/**
 * Player settings stored locally, so only used for this current device
 */
const usePlayerLocalSettingsStore = defineStore('playerLocalSettingsStore', () => {
    /**
     * Player settings stored locally, so only used for this current device
     */
    const localSettings = ref(loadLocalSettings());
    watch(localSettings.value, () => saveLocalSettings(localSettings.value));
    // When open game page on small screen, make openSidebar auto to hide it.
    // Or on larger screen, also make auto so it stays open, but will close when reducing screen.
    if (window.screen.width < 576 || localSettings.value.openSidebar) {
        localSettings.value.openSidebar = undefined;
    }
    /*
     * Dark/light theme
     */
    const displayedTheme = () => {
        if ('auto' !== localSettings.value.selectedTheme) {
            return localSettings.value.selectedTheme;
        }
        return getSystemPreferredTheme();
    };
    const getSystemPreferredTheme = () => window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light';
    const switchTheme = () => {
        localSettings.value.selectedTheme = displayedTheme() === 'light'
            ? 'dark'
            : 'light';
    };
    watchEffect(() => {
        localStorage === null || localStorage === void 0 ? void 0 : localStorage.setItem('selectedTheme', localSettings.value.selectedTheme);
        document.documentElement.setAttribute('data-bs-theme', displayedTheme());
    });
    return {
        localSettings,
        switchTheme,
        displayedTheme,
    };
});
export default usePlayerLocalSettingsStore;
