var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { IsNumber, IsOptional, IsString, Max, Min, ValidateNested } from 'class-validator';
import { Expose } from '../class-transformer-custom';
export class HostedGameOptionsTimeControl {
}
__decorate([
    Expose(),
    IsString(),
    __metadata("design:type", String)
], HostedGameOptionsTimeControl.prototype, "type", void 0);
__decorate([
    Expose(),
    ValidateNested(),
    __metadata("design:type", Object)
], HostedGameOptionsTimeControl.prototype, "options", void 0);
const oneSecond = 1000;
const twoWeeks = 14 * 86400 * 1000;
export class OptionsFischer {
}
__decorate([
    IsNumber(),
    Min(oneSecond),
    Max(twoWeeks),
    Expose(),
    __metadata("design:type", Number)
], OptionsFischer.prototype, "initialTime", void 0);
__decorate([
    IsNumber(),
    Min(0),
    Max(twoWeeks),
    Expose(),
    __metadata("design:type", Number)
], OptionsFischer.prototype, "timeIncrement", void 0);
__decorate([
    IsOptional(),
    IsNumber(),
    Min(oneSecond),
    Max(twoWeeks),
    Expose(),
    __metadata("design:type", Number)
], OptionsFischer.prototype, "maxTime", void 0);
export class HostedGameOptionsTimeControlFischer extends HostedGameOptionsTimeControl {
}
__decorate([
    Expose(),
    __metadata("design:type", String)
], HostedGameOptionsTimeControlFischer.prototype, "type", void 0);
__decorate([
    ValidateNested(),
    __metadata("design:type", OptionsFischer)
], HostedGameOptionsTimeControlFischer.prototype, "options", void 0);
export class OptionsByoYomi {
}
__decorate([
    IsNumber(),
    Min(oneSecond),
    Max(twoWeeks),
    Expose(),
    __metadata("design:type", Number)
], OptionsByoYomi.prototype, "initialTime", void 0);
__decorate([
    IsNumber(),
    Min(oneSecond),
    Max(twoWeeks),
    Expose(),
    __metadata("design:type", Number)
], OptionsByoYomi.prototype, "periodTime", void 0);
__decorate([
    IsNumber(),
    Min(1),
    Max(50),
    Expose(),
    __metadata("design:type", Number)
], OptionsByoYomi.prototype, "periodsCount", void 0);
export class HostedGameOptionsTimeControlByoYomi extends HostedGameOptionsTimeControl {
}
__decorate([
    Expose(),
    __metadata("design:type", String)
], HostedGameOptionsTimeControlByoYomi.prototype, "type", void 0);
__decorate([
    ValidateNested(),
    __metadata("design:type", OptionsByoYomi)
], HostedGameOptionsTimeControlByoYomi.prototype, "options", void 0);
